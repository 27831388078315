import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import globe from './globe-white.png';

const useStyles = makeStyles(theme => ({
  footerParent: {
    height: '350px',
    // marginTop: '100px',
    background: '#253D4E',
    [theme.breakpoints.down('sm')]: {
      minHeight: '500px',
      textAlign: 'center',
    },
  },
  footBeg: {
    padding: '50px 50px 50px 150px',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
      width: '100%',
      height: '150px',
    },
  },
  content: {
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '20px',
      paddingLeft: '20px',
      textAlign: 'center',
    },
  },
  companyContent: {
    paddingTop: '10px',
    width: '90%',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  linkSect: {
    padding: '75px 20px 20px 0px',
    textAlign: 'center',
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      paddingRight: '20px',
      // textAlign: 'center',
    },
  },
  linkHeader: {
    fontSize: '18px !important',
  },
  newsletter: {
    display: 'flex',
    gap: 5,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  newsLetter: {
    display: 'flex',
    justifyContent: 'start',
    padding: '55px 0px 50px 50px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  lilBlurb: {
    paddingTop: '10px',
  },
  blb: {
    fontSize: '10px !important',
  },
  purposeDiv: {
    textAlign: 'center',
    paddingRight: '40px',
  },
  socialLinks: {
    display: 'flex',
    justifyContent: 'end',
  },
  follow: {
    textAlign: 'start',
    paddingTop: '20px',
    fontSize: '16px !important',
  },
  divider: {
    border: '1px solid darkGray',
  },
  div: {
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },
  rights: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  socialIcon: {
    height: '20px',
    width: '19px',
  },
  logo: {
    height: '80px',
    width: 'auto',
  },
  logoDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '25px',
    [theme.breakpoints.down('md')]: {
      marginTop: '10px',
      marginLeft: '10px',
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  item: {
    justifyContent: 'center',
  },
  linker: {
    textDecoration: 'none !important',
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <Grid container className={classes.footerParent}>
      <Grid className={classes.footBeg} item xs={12} lg={5}>
        <div className={classes.logoDiv}>
          <img alt="whitelogo" src={globe} className={classes.logo} />
        </div>
        <div className={classes.companyContent}>
          <Typography
            color="white"
            className={classes.content}
            variant="subtitle1"
          >
            Obsidian Global Services (OGS) is a global IT consulting and service provider
          </Typography>
        </div>
      </Grid>
      <Grid item xs={6} lg={3} className={classes.linkSect}>
        <Typography className={classes.linkHeader} variant="h4" color="white">
          Obsidian Information
        </Typography>
        <Link className={classes.linker} to="/who-we-are">
          <Typography color="white">
            Who We Are
          </Typography>
        </Link>
        <Link className={classes.linker} to="/who-we-are">
          <Typography color="white">
            Our Philosophy
          </Typography>
        </Link>
        <Link className={classes.linker} to="/who-we-are">
          <Typography color="white">
            Our People
          </Typography>
        </Link>
        <Link className={classes.linker} to="/contact">
          <Typography color="white">
            Contact Us
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={6} lg={3} className={classes.linkSect}>
        <Typography className={classes.linkHeader} variant="h4" color="white">
          Our Strategies
        </Typography>
        <Link className={classes.linker} to="/ogs-strategies">
          <Typography color="white">
            Web & Mobile Development
          </Typography>
        </Link>
        <Link className={classes.linker} to="/ogs-strategies">
          <Typography color="white">
            Data & Infrastructure
          </Typography>
        </Link>
        <Link className={classes.linker} to="/ogs-strategies">
          <Typography color="white">
            Staffing
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={12} className={classes.div}>
        <div className={classes.divider} />
      </Grid>
      <Grid item xs={12} lg={12} className={classes.rights}>
        <Typography variant="subtitle1" color="white">
          © 2023 Obsidian Global | All Rights Incorporated
        </Typography>
      </Grid>
    </Grid>
  );
}
