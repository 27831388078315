import React from 'react';
import { HomePage } from './Pages/HomePage/Loadable.tsx';
import { WhoWeArePage } from './Pages/WhoWeArePage/Loadable.tsx';
import { StrategyPage } from './Pages/StrategyPage/Loadable.tsx';
import { AutomatedTestArticlePage } from './Pages/AutomatedTestArticlePage/Loadable.tsx';
import { DeliveryParadigmPage } from './Pages/DeliveryParadigmPage/Loadable.tsx';
import { StoriesIndexPage } from './Pages/StoriesIndexPage/Loadable.tsx';
import { ContactPage } from './Pages/ContactPage/Loadable.tsx';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import './globalStyle.css';
import theme from './theme';
import RegularLayout from './Layouts/regular';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RegularLayout />}>
              <Route index element={<HomePage />} />
              <Route path="/who-we-are" element={<WhoWeArePage />} />
              <Route path="/ogs-strategies" element={<StrategyPage />} />
              <Route path="/ogs-views/software-quality" element={<AutomatedTestArticlePage />} />
              <Route path="/ogs-views/software-contracting-strategies" element={<DeliveryParadigmPage />} />
              <Route path="/ogs-views" element={<StoriesIndexPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </ThemeProvider>
  )
}
