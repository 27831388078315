import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

export default function RegularLayout(props) {
  return (
    <div id="content">
      <Header />
      <main style={{ minHeight: '1000px' }}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}
