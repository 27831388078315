import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material';

const brightGray = '#242d39';
const trueGray = '#b0c9eb';
const slateGray = '#777e90';
const shuttleGray = '#596677';
const ebony = '#0d0a19';
const green = '#1ba94c';
const white = '#ffffff';
const red = '#d11534';
const aliceBlue = '#f6f7f8';
const grayBackgroundColor = 'linear-gradient(180deg, #0e141e 0%, #39424e 100%)';
const lightBlue = '#257bbf';
const goshtWhite = '#FCFCFD';
const darkBlue = '#008EFF';
const yellow = '#FFD166';

export default createMuiTheme({
  palette: {
    common: {
      darkGray: brightGray,
      lightGray: slateGray,
      shuttleGray: shuttleGray,
      trueGray: trueGray,
      green: green,
      white: white,
      red: red,
      aliceBlue: aliceBlue,
      grayBackgroundColor: grayBackgroundColor,
      lightBlue: lightBlue,
      ebony: ebony,
      goshtWhite: goshtWhite,
      darkBlue: darkBlue,
      yellow: yellow,
    },
    primary: {
      main: brightGray,
    },
    secondary: {
      main: slateGray,
    },
  },
  typography: {
    h2: {
      fontFamily: 'Archivo, Helvetica',
      fontWeight: 700,
      fontSize: '2rem',
      color: ebony,
      lineHeight: 1.5,
      marginBottom: '10px',
    },
    h3: {
      fontFamily: 'Archivo, Helvetica',
      fontSize: '1.875rem',
      fontWeight: 700,
      color: ebony,
      marginBottom: '10px',
    },
    h4: {
      fontFamily: 'Archivo, Helvetica',
      fontSize: '1.55rem',
      color: ebony,
      fontWeight: 700,
      marginBottom: '10px',
    },
    h5: {
      fontFamily: 'Archivo, Helvetica',
      fontSize: '1rem',
      color: ebony,
      fontWeight: 600,
      marginBottom: '10px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '0.875rem',
      fontFamily: 'Archivo, Helvetica',
      color: ebony,
      marginBottom: '10px',
    },
    subtitle1: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: slateGray,
      marginBottom: '10px',
      fontFamily: 'Archivo, Helvetica',
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: aliceBlue,
      marginBottom: '10px',
      fontFamily: 'Archivo, Helvetica',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      fontFamily: 'Archivo, Helvetica',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      fontFamily: 'Archivo, Helvetica',
    },
    tab: {
      fontFamily: 'Archivo, Helvetica',
      textTransform: 'none',
      fontSize: '1rem',
      color: 'white',
    },
  },
});
