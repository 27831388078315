import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: 'white !important',
    paddingRight: '30px',
    paddingLeft: '0px',
    maxWidth: '100%',
    '@media (max-width: 900px)': {
      paddingRight: 0,
    },
  },
  desktopLogo: {
    height: '40px',
    width: 'auto',
  },
  logo: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 600,
    color: '#FFFEFE',
    textAlign: 'left',
  },
  menuButton: {
    fontWeight: 700,
    fontSize: '18px !important',
    fontFamily: 'Proxima Nova !important',
    marginLeft: '38px',
    '&:focus': {
      background: 'none !important',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
      gap: '20px',
    },
  },
  drawerContainer: {
    padding: '20px 30px',
  },
  drawLogo: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '60px',
      width: 'auto',
    },
  },
  navLogo: {
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 'auto',
      height: '40px',
      width: 'auto',
    },
  },
  paper: {
    minWidth: '37%',
  },
  toolBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  mobileImgDiv: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingBottom: '0px',
    },
    paddingBottom: '30px',
  },
  btnGrid: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: '28px !important',
  },
  loginGrid: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
  },
  routerLink: {
    textDecoration: 'none !important',
  },
  walksBtn: {
    fontWeight: '500 !important',
    padding: '7px !important',
    height: '32px',
    marginTop: '8px !important',
    fontSize: '12px !important',
    color: 'white !important',
    background: '#00A2B8 !important',
  },
  myWalksBtn: {
    fontWeight: '500 !important',
    padding: '7px 10px !important',
    justifyContent: 'space-evenly !important',
    border: '1.2px solid #253D4E !important',
    fontSize: '12px !important',
    maxHeight: '40px !important',
  },
  loginBtn: {
    background: '#00A2B8 !important',
    color: 'white !important',
    padding: '7px 15px !important',
    border: '1.2px solid #00A2B8',
    fontSize: '12px !important',
    paddingLeft: '0px !important',
    justifyContent: 'space-evenly !important',
    width: '109px !important',
    maxHeight: '40px !important',
  },
  meGrid: {
    display: 'flex',
    justifyContent: 'end',
    gap: '0px',
  },
  name: {
    textAlign: 'center',
    position: 'relative',
    top: '8px',
  },
  personalInfo: {
    display: 'flex',
    gap: 4,
  },
  menuIcon: {
    color: '#253D4E',
    marginRight: '30px',
  },
}));

export default useStyles;
