import React, { useState, useEffect } from 'react';
import {
  Grid,
  AppBar,
  IconButton,
  Toolbar,
  Drawer,
  Link,
  MenuItem,
  Button,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useStyles from './styles.js';
import { Link as RouterLink } from 'react-router-dom';
import globe from './globe.png';


const headersData = [
  {
    label: 'Who We Are',
    href: '/who-we-are',
  },
  {
    label: 'Approach',
    href: '/ogs-strategies',
  },
  {
    label: 'Stories',
    href: '/ogs-views',
  },
  {
    label: 'Contact',
    href: '/contact',
  },
];

export default function Header(props) {
  // const navigate = useNavigate();
  const classes = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  // const login = event => navigate('/login');
  // const signUp = event => navigate('/account-select');

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <RouterLink to="/">
              <img alt="dlogo" src={globe} className={classes.desktopLogo} />
            </RouterLink>
          </Grid>
          <Grid item xs={10} className={classes.btnGrid}>
            <div>{getMenuButtons()}</div>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState(prevState => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState(prevState => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={classes.toolbar}>
        <div className={classes.mobileImgDiv}>
          <img alt="nlogo" className={classes.navLogo} src={globe} />
        </div>
        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>

        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
            classes: {
              paper: classes.paper,
            },
          }}
        >
          <div className={classes.drawerContainer}>{getDrawerChoices()}</div>
          <div>
            <img alt="drawLogo" className={classes.drawLogo} src={'https://d3cpvcf3qbaivs.cloudfront.net/assets/newLogo.png'} />
          </div>
        </Drawer>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: 'inherit',
            style: { textDecoration: 'none' },
            key: label,
          }}
        >
          <MenuItem>
            <Typography variant="body3">{label}</Typography>
          </MenuItem>
        </Link>
      );
    });
  };

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: 'inherit',
            href: href,
            className: classes.menuButton,
            sx: {
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          }}
        >
          <Typography
            sx={{
              color: '#002f6c',
              fontWeight: 700,
              fontSize: '14px',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {label}
          </Typography>
        </Button>
      );
    });
  };

  return (
    <AppBar classes={{ root: classes.header }}>
      {mobileView ? displayMobile() : displayDesktop()}
    </AppBar>
  );
}
